<template>
  <div>
    <div class="info">
      <top-bar :title="!submitData.id?'新增违章建筑':edit?'修改违章建筑':'违章建筑信息'" :left="true"></top-bar>
      <!-- 违章建筑主体类型 -->
      <van-popup v-model="builderTypeShow" position="bottom">
        <van-picker title="请选择" show-toolbar :columns="selectData.builderTypeList" value-key="label"
                    @confirm="builderTypeConfirm" @cancel="builderTypeShow = false"/>
      </van-popup>
      <!-- 选择房屋 -->
      <van-popup v-model="buildingHouseShow" position="bottom" >
        <van-cascader v-model="submitData.buildingHouse" title="请选择" :options="selectData.buildingHouseList"
                      @close="buildingHouseShow = false" @finish="buildingHouseFinish" :field-names="fieldNames"/>
      </van-popup>
      <!-- 违章类型 -->
      <van-popup v-model="builtTypeShow" position="bottom">
        <van-picker title="请选择" show-toolbar :columns="selectData.builtTypeList" value-key="label"
                    @confirm="builtTypeConfirm" @cancel="builtTypeShow = false"/>
      </van-popup>
      <!-- 违建日期 -->
      <van-popup v-model="illegalDateShow" position="bottom">
        <van-datetime-picker v-model="submitData.illegalDate" type="date" title="请选择" :formatter="formatDate"
                             @cancel="illegalDateShow = false" @confirm="timeConfirm"/>
      </van-popup>


      <!-- 处理方式 -->
      <van-popup v-model="handleTypeShow" position="bottom">
        <van-picker title="请选择" show-toolbar :columns="selectData.handleTypeList" value-key="label"
                    @confirm="handleTypeConfirm" @cancel="handleTypeShow = false"/>
      </van-popup>

      <!-- 处理日期 -->
      <van-popup v-model="handleDateShow" position="bottom">
        <van-datetime-picker v-model="submitData.handleDate" type="date" title="请选择" :formatter="formatDate"
                             @cancel="handleDateShow = false" @confirm="handleDateConfirm"/>
      </van-popup>

      <van-cell-group>
        <van-cell>
          <template #title>
            <div class="blue-box"></div>
            <span class="custom-title">违章建筑信息</span>
          </template>
        </van-cell>
        <van-cell title="违章建筑主体类型" :value="selectMsg.builderType" @click="builderTypeShow = edit" :clickable="edit"
                  :value-class="{'value-common':selectMsg.builderType=='请选择'}" :is-link="edit"/>
        <van-cell title="违章房屋" :value="selectMsg.address" @click="buildingHouseShow = edit" :clickable="edit"
                  v-if="submitData.builderType==1"
                  :value-class="{'value-common':selectMsg.address=='请选择'}" :is-link="edit"/>

        <van-cell :title="edit?'选择场所':'场所'" :value="selectMsg.place" @click="selectPlace" v-if="submitData.builderType==2"
                  :clickable="edit"
                  :value-class="{'value-common':selectMsg.place=='请选择'}" :is-link="edit"/>
        <van-cell title="违章类型" :value="selectMsg.builtType" @click="builtTypeShow = edit"
                  :value-class="{'value-common':selectMsg.builtType=='请选择'}" :is-link="edit"/>
        <van-cell title="占地面积">
          <van-field clearable clear-trigger="always" type="number" v-model="submitData.areaCovered" placeholder="请输入"
                     :readonly="!edit"/>
        </van-cell>
        <van-cell title="违章面积">
          <van-field clearable clear-trigger="always" type="number" v-model="submitData.area" placeholder="请输入" :readonly="!edit"/>
        </van-cell>
        <van-cell title="联系电话">
          <van-field clearable clear-trigger="always" type="tel" v-model="submitData.mobile" placeholder="请输入" :readonly="!edit"/>
        </van-cell>
        <van-cell title="违建日期" :value="selectMsg.illegalDate" @click="illegalDateShow = edit"
                  :value-class="{'value-common':selectMsg.illegalDate=='请选择'}" :is-link="edit"/>

        <van-row v-if="edit||fileList.length!==0">
          <van-col :span="24">
            <van-uploader v-model="fileList" @delete="uploaddel" :after-read="uploadRead" :deletable="edit"
                          :show-upload="edit" :before-read="beforeUpload"/>
          </van-col>
        </van-row>

      </van-cell-group>

      <van-cell-group v-if="dispose || alreadyDispose" title=" ">
        <van-cell>
          <template #title>
            <div class="blue-box"></div>
            <span class="custom-title">处理情况</span>
          </template>
        </van-cell>

        <van-cell title="处理方式" :value="selectMsg.handleType" @click="handleTypeShow = dispose" :clickable=" dispose"
                  :value-class="{'value-common':selectMsg.handleType=='请选择'}" :is-link=" dispose"/>
        <van-cell title="处置日期" :value="selectMsg.handleDate" @click="handleDateShow = dispose"
                  :value-class="{'value-common':selectMsg.handleDate=='请选择'}" :is-link="dispose"/>
        <van-row v-if="dispose||handleFileList.length!==0">
          <van-col :span="24">
            <van-uploader v-model="handleFileList" @delete="uploaddel" :after-read="uploadRead" :deletable="dispose"
                          :show-upload="dispose" :before-read="beforeUpload"/>
          </van-col>
        </van-row>
      </van-cell-group>
      <van-row class="btns" v-if="!alreadyDispose&&submitData.id">
        <van-row gutter="10" v-if="edit">
          <van-col :span="12" >
            <van-button plain type="danger" size="large" round @click="deleteHandle"
                        style="background-color:transparent">删除
            </van-button>
          </van-col>
          <van-col :span="12" >
            <van-button type="info" size="large" :loading="loading" round @click="submit">完成</van-button>
          </van-col>
        </van-row>
        <van-row gutter="10" v-else>
          <template  v-if="!dispose">
            <van-col :span="12">
              <van-button type="info" size="large" round @click="edit=true">编辑</van-button>
            </van-col>
            <van-col :span="12">
              <van-button type="info" size="large" round @click="dispose =true" style="background-color:transparent;color:#FFA93A;border-color:#FFA93A">处理</van-button>
            </van-col>
          </template>
          <van-col :span="24" v-else>
            <van-button type="info" size="large" round @click="submit">完成</van-button>
          </van-col>
        </van-row>
      </van-row>
      <van-row v-if="!submitData.id" class="btns">
        <van-col :span="24">
          <van-button type="info" size="large" round @click="submit">完成</van-button>
        </van-col>
      </van-row>
    </div>
  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'
import {beforeFileUpload, formatterDate} from "@/utils/utils"
import {getImageStream} from '@/utils/index'

export default {
  name: 'illegalBuilding-add',
  data() {
    return {
      edit: false,
      dispose:false, //处理状态
      alreadyDispose:false, //原本已处理
      fieldNames: {
        text: 'label',
        value: 'value',
        children: 'children',
      },
      loading: false,
      builderTypeShow: false,
      buildingHouseShow: false,
      placeShow: false,
      builtTypeShow: false,
      illegalDateShow: false,
      handleTypeShow: false,
      handleDateShow:false,
      selectMsg: {
        //违章主体类型
        builderType: '请选择',
        //违章类型
        builtType: '请选择',
        place:'请选择',
        //违章房屋
        buildingHouse: '请选择',
        //违章建筑占地面积
        areaCovered: '请输入',
        //违章面积
        area: '请输入',
        //联系电话
        mobile: '请输入',
        //地址
        address: '请输入',
        //违建日期
        illegalDate: '请输入',
        //违章主体
        builder: '',
        fileList: [],
        newFileMessages: [],
        deleteFiles: [],
        //处理状态
        handleType: '请选择',
        handleDate:'请选择'
      },
      submitData: {
        id: '',
        //违章主体类型
        builderType: '',
        //违章类型
        builtType: '',
        place:'',
        //违章房屋/商户
        buildingHouse: '',
        //违章建筑占地面积
        areaCovered: '',
        //违章面积
        area: '',
        //联系电话
        mobile: '',
        //地址
        address: '',
        //违建日期
        illegalDate: '',
        //违章主体
        builder: '',
        fileList: [],
        newFileMessages: [],
        deleteFiles: [],
        //处理状态
        handleType: 0,
        handleDate:''
      },
      selectData: {
        builderTypeList: [{ label: "居民房屋", value: "1" }, { label: "商户", value: "2" }],
        builtTypeList: [{ label: "阳光房", value: "1" }, { label: "天井披棚", value: "2" }, { label: "乱搭乱建", value: "3" }, { label: "拆迁区域违建", value: "4" }],
        buildingHouseList:[],
        handleTypeList: [{ label: "拆除", value: "1" }, { label: "整治", value: "2" }]
      },
      fileList: [],
      handleFileList: [],
    }
  },
  components: {
    topBar
  },
  methods: {
    // 删除图片
    uploaddel(file, detail) {
      let i = detail.index
      this.submitData.newFileMessages.splice(i, 1)
      if (file.id !== 0) {
        this.submitData.deleteFiles.push(file.id)
      }
    },
    // 文件上传前的回调
    beforeUpload(file) {
      this.$toast.loading({
        message: '上传中...',
        forbidClick: true,
        duration: 0,
        overlay: true
      })
      return beforeFileUpload(file)
    },
    // 上传图片
    uploadRead(file) {
      if (!Array.isArray(file)) {
        file = [file]
      }
      file.forEach((item, index2) => {
        let formdata = new FormData();
        formdata.append("file", item.file)
        formdata.append("path", 'files/app/illegalBuilding/' + this.$globalData.userInfo.orgId)
        this.$http({
          url: this.$http.adornUrl('/wxapp/file/upload'),
          method: 'post',
          data: formdata
        }).then(({data}) => {
          if (index2 === file.length - 1) {
            this.$toast.clear()
          }
          if (data.code == 0) {
            let fileMessage = data.fileMessage
            fileMessage['uid'] = this.fileList.length != 0 ? this.fileList.length : 0
            this.submitData.newFileMessages.push(fileMessage)
            return true
          } else {
            this.$toast.fail(data.msg)
          }
        })
      })
    },
    // 初始化
    init() {
      let nowDate = new Date()
      this.maxDate = nowDate
      this.selectMsg.illegalDate = formatterDate(nowDate)
      this.submitData.illegalDate = nowDate
      this.selectMsg.handleDate = formatterDate(nowDate)
      this.submitData.handleDate = nowDate
      if (this.submitData.id) {
        this.edit = false
        this.getInfo()
      } else {
        this.edit = true
      }

    },
    // 获取房屋列表
    getBuildingList() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/building/house/liveWhichTree'),
        method: 'get',
        params: this.$http.adornParams({
          communityId: this.$orgId
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.selectData.buildingHouseList = data.liveWhichTree
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    // 获取详情
    getInfo() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/illegalbuilding/info/'+this.submitData.id),
        method: 'post',
        params: this.$http.adornParams({
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.submitData.id=data.appIllegalBuildingVO.id
          this.submitData.builderType=data.appIllegalBuildingVO.builderType
          this.selectMsg.builderType=data.appIllegalBuildingVO.builderTypeStr
          this.submitData.builtType=data.appIllegalBuildingVO.builtType
          this.selectMsg.builtType=data.appIllegalBuildingVO.builtTypeStr
          let houseId=data.appIllegalBuildingVO.houseId
          if(this.submitData.builderType==1){
            this.submitData.buildingHouse=houseId
            this.getBuildingList()
          }else if(this.submitData.builderType==2) {
            this.submitData.place=houseId
            this.selectMsg.place=data.appIllegalBuildingVO.address
          }
          this.submitData.address=data.appIllegalBuildingVO.address
          this.selectMsg.address=data.appIllegalBuildingVO.address
          this.submitData.areaCovered=data.appIllegalBuildingVO.areaCovered
          this.selectMsg.areaCovered=data.appIllegalBuildingVO.areaCoveredStr
          this.submitData.area=data.appIllegalBuildingVO.area
          this.selectMsg.area=data.appIllegalBuildingVO.area
          this.submitData.mobile=data.appIllegalBuildingVO.mobile
          this.selectMsg.mobile=data.appIllegalBuildingVO.mobile
          this.submitData.illegalDate=data.appIllegalBuildingVO.builtDate
          this.selectMsg.illegalDate=data.appIllegalBuildingVO.builtDate
          this.selectMsg.handleType=data.appIllegalBuildingVO.handleTypeStr
          this.submitData.handleType=data.appIllegalBuildingVO.handleType
          if(data.appIllegalBuildingVO.handleDate){
            this.submitData.handleDate=data.appIllegalBuildingVO.handleDate
            this.selectMsg.handleDate=data.appIllegalBuildingVO.handleDate
          }
          this.alreadyDispose = data.appIllegalBuildingVO.handleType? true:false
          // newFileMessages: that.data.dataForm.newFileMessages,
          this.submitData.fileMessages= data.appIllegalBuildingVO.fileList  //真实服务器图片路径数组
          let files =data.appIllegalBuildingVO.fileList
          let urls1 = []
          let urls2 = []
          for (let i in files) {
            let file = files[i]
            let u
            u = getImageStream(files[i].filePath)

            if(file.handleFile==0){
              let url = {
                uid: urls1.length,
                id: file.id,
                status: 'done',//uploading上传中，done上传完成，error上传失败
                fileType: file.type,
                url: u,
                realUrl: file.filePath.replace(/\\/g, "/")
              }
              urls1.push(url);
            }else if(file.handleFile==1){
              let url = {
                uid: urls2.length,
                id: file.id,
                status: 'done',//uploading上传中，done上传完成，error上传失败
                fileType: file.type,
                url: u,
                realUrl: file.filePath.replace(/\\/g, "/")
              }
              urls2.push(url);
            }
          }

          this.fileList = urls1
          this.handleFileList=urls2

        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    deleteHandle() {
      this.$dialog.confirm({
        title: '确定删除吗？'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/wxapp/illegalbuilding/delete'),
          method: 'post',
          params: this.$http.adornParams({
            id: parseInt(this.submitData.id)
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.$toast.success({
              message: '删除成功',
              duration: 3000,
              onOpened: () => {
                setTimeout(() => {
                  this.$router.go(-1)
                }, 1500)
              }
            })
          } else {
            this.$toast.fail(data.msg)
          }
        })
      })
    },

    // 房屋选择
    buildingHouseFinish(value) {
      if (value) {
        let options = value.selectedOptions
        let right =options[options.length-1]
        if(options && options.length>1){
          if(right && right.depth==2 && right.parameter && right.parameter>0){
            this.submitData.buildingHouse = right.parameter+""
            let buildingHouse=""
            value.selectedOptions.map(e=>{
              buildingHouse+=e.label
            })
            this.submitData.address = buildingHouse
            this.selectMsg.address = buildingHouse
          }else if(right && right.depth==4){
            this.submitData.buildingHouse = right.value+""
            let buildingHouse=""
            value.selectedOptions.map(e=>{
              buildingHouse+=e.label
            })
            this.submitData.address = buildingHouse
            this.selectMsg.address = buildingHouse
          }else{
            this.submitData.buildingHouse=""
            this.submitData.address=""
            this.selectMsg.address="请输入"
          }
        }else{
          this.submitData.buildingHouse=""
          this.submitData.address=""
          this.selectMsg.address="请输入"
        }

      }
      this.buildingHouseShow = false
    },
    //违章建筑类型
    builderTypeConfirm(value){
      if (value) {
        this.selectMsg.builderType = value.label
        this.submitData.builderType = value.value
        this.selectMsg.address="请输入"
        this.submitData.address=""
        if (value.value == 1) {
          this.selectMsg.place=""
          this.submitData.place=""
          this.getBuildingList()
        } else {
          this.submitData.buildingHouse=""
          // this.getPublicBuildingList(value.value)
        }
      }
      this.builderTypeShow = false
    },
    builtTypeConfirm(value){
      if (value) {
        this.selectMsg.builtType = value.label
        this.submitData.builtType = value.value
      }
      this.builtTypeShow = false
    },
    // 选择场所
    selectPlace() {
      this.$router.push('./place-list')
    },

    handleTypeConfirm(value){
      if (value) {
        this.selectMsg.handleType = value.label
        this.submitData.handleType = value.value
      }
      this.handleTypeShow = false
    },
    // 提交
    submit() {
      let houseId="";
      if (this.submitData.builderType === '') {
        return this.$toast.fail('失败:请选择违章主体类型');
      }
      if (this.submitData.builderType==1) {
        if (this.submitData.buildingHouse === '') {
          return this.$toast.fail('失败:请选择违章房屋');
        }else{
          houseId=this.submitData.buildingHouse
        }
      }else if(this.submitData.builderType==2) {
        if (this.submitData.place === '') {
          return this.$toast.fail('失败:请选择违章场所');
        }else{
          houseId=this.submitData.place
        }
      }
      if (this.submitData.builtType === '') {
        return this.$toast.fail('失败:请选择违章类型');
      }

      if(this.disable){
        if (this.submitData.handleType === ''||this.submitData.handleType ==0) {
          return this.$toast.fail('失败:请选择处理方式');
        }
        if (this.submitData.handleDate === '') {
          return this.$toast.fail('失败:请选择处理时间');
        }
      }

      this.loading = true
      this.$http({
        url: this.$http.adornUrl(`/wxapp/illegalbuilding/save`),
        method: 'post',
        data: {
          id:this.submitData.id,
          builderType: this.submitData.builderType,
          builtType: this.submitData.builtType,
          houseId: houseId,
          address: this.submitData.address,
          areaCovered: this.submitData.areaCovered,
          area: this.submitData.area,
          mobile: this.submitData.mobile,
          builtDate: this.submitData.illegalDate,
          // newFileMessages: that.data.dataForm.newFileMessages,
          // fileMessages: this.submitData.fileList,  //真实服务器图片路径数组
          newFileMessages:this.submitData.newFileMessages, //新增图片路径数组
          deleteFiles:this.submitData.deleteFiles, //删除图片的id
          builder: this.submitData.builder,
          handleType: this.submitData.handleType,
          handleDate: this.submitData.handleType?this.submitData.handleDate: ''
        }
      }).then(({data}) => {
        if (data.code == 0) {
          this.$toast.success({
            message: `${this.submitData.id ? '修改成功' : '新增成功'}`,
            duration: 3000,
            onOpened: () => {
              setTimeout(() => {
                this.$router.go(-1)
              }, 1500)
            }
          })
        } else {
          this.loading = false
          this.$toast.fail(data.msg);
        }
      })
      this.loading = false
    },
    // 日期选项格式化
    formatDate(type, val) {
      if (type === 'year') {
        return val + '年'
      }
      if (type === 'month') {
        return val + '月'
      }
      if (type === 'day') {
        return val + '日'
      }
      if (type === 'hour') {
        return val + '时'
      }
      if (type === 'minute') {
        return val + '分'
      }
      return val
    },
    timeConfirm(value) {
      if (value) {
        this.selectMsg.illegalDate = formatterDate(value)
        this.submitData.illegalDate = formatterDate(value)
      }
      this.illegalDateShow = false
    },
    handleDateConfirm(value) {
      if (value) {
        this.selectMsg.handleDate = formatterDate(value)
        this.submitData.handleDate = formatterDate(value)
      }
      this.handleDateShow = false
    }
  },
  activated () {
    if (this.$store.state.placeInfo.addrId) {
      this.submitData.buildingHouse = ''
      this.selectMsg.place = this.$store.state.placeInfo.placeTitle
      this.submitData.place = this.$store.state.placeInfo.addrId
      this.submitData.address=this.$store.state.placeInfo.address
    }
  },
    created() {
      this.submitData.id = this.$route.query.id || ''
      document.querySelector('html').style.backgroundColor = '#fff'
      this.init()
    },
    beforeDestroy() {
      this.$store.state.placeInfo = {}
      document.querySelector('html').style.backgroundColor = ''
    }
  }
</script>
<style scoped>
.left >>> .van-field__control {
  text-align: left !important;
}

.left >>> .van-icon {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
